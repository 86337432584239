import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

// Connects to data-controller="sites"
export default class extends Controller {
  static targets = [ "changeLanguageForm" ]

  connect() {
      this.pageResize();
      this.setupSortableTable()
      this.setupToolTips()
  }

  pageResize(){
  //Make all input boxes labels same width
    if(document.querySelector('.input-group > .input-group-prepend > .input-group-text')){
      var collapsed = document.querySelectorAll('.collapse');
      collapsed.forEach(element => { element.style.display = 'block' });
      var dNone = document.querySelectorAll('.d-none');
      dNone.forEach(element => { element.classList.remove('d-none') })
      // var modal = $('.modal');
      // modal.show();
      document.querySelectorAll('.input-group > .input-group-prepend > .input-group-text').forEach(element => element.style.width = '');

      var mWidth = 0;
      var inputs = document.querySelectorAll('.input-group > .input-group-prepend > .input-group-text')
      inputs.forEach(element => {
        var tWidth = element.offsetWidth;
        if (Number(mWidth) < Number(tWidth)) {
          mWidth = tWidth;
        }
      });

      document.querySelectorAll('.input-group > .input-group-prepend > .input-group-text:not(.no-resize)').forEach(element => element.style.width = mWidth + 'px');
      document.querySelectorAll('.input-group > .input-group-prepend > .input-group-text').forEach(element => element.style.display = 'block');
      document.querySelectorAll('.input-group > .input-group-prepend > .input-group-text').forEach(element => element.style.textAlign = 'right');
      collapsed.forEach(element => { element.style.display = '' });
      dNone.forEach(element => { element.classList.add('d-none') })
      // modal.hide();
    }
  }

  setupSortableTable() {
    if (document.querySelector('table[data-sortable]')) {
      // These double falses are to trick sortable into thinking it needs to initiate on turbo load
      sorttable.init.done = false;
      sorttable.init();
    }
  }

  setupToolTips() {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })
  }

  changeLanguage(event) {
    if (this.hasChangeLanguageFormTarget) {
      this.changeLanguageFormTarget.submit();
    }
  }
}
