import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

// Connects to data-controller="sites"
export default class extends Controller {
  static targets = [
    "newFeatureModalA", "newFeatureModalB",
    "deleteSiteModal", "oldFirmwareVersionWarningModal", "sitesTable",
    "canUseHistory", "canViewBaseInfoForHistory",
    "yearlyBilling", "yearlyBillingCheckbox", "repeatBilling",
    "removeMobileUsersOption",
    "radioLink", "planOptions", "planSelection",
    "oneHourPollFail", "oneHourPollFailMonthly", "oneHourPollFailYearly",
    "advancedPersonalReporting", "advancedPersonalReportingMonthly", "advancedPersonalReportingYearly",
    "additionalMobileUsersAndPersonalReportingRules", "additionalMobileUsersAndPersonalReportingRulesMonthly", "additionalMobileUsersAndPersonalReportingRulesYearly",
    "emergencyVoiceCalls", "emergencyVoiceCallsMonthly", "emergencyVoiceCallsYearly",
    "dualSims", "dualMyAlarmSimsMonthly", "dualMyAlarmSimsYearly",
    "radioSerialInput", "hiddenRadioSerialInput",
    "additionalMobileUsersAndPersonalReportingRulesCheckbox",
    "emergencyVoiceCallsCheckbox", "emergencyVoiceCallsModal",
    "emergencyVoiceCallRegions",
    "advancedPersonalReportingCheckbox", "advancedPersonalReportingModal",
    "removeAdditionalUsersAndRulesModal", "suspendIfobOrPersonalReportingModal",
    "suspendIfobCheckbox", "suspendPersonalReportingCheckbox",
    "changeSiteSortOrderForm", "unpaidInvoiceWarningModal",
    "updateSiteEditForm",
    "unpaidInvoiceWarningSubmitButton",
    "unpaidInvoiceChangePlanCancelInvoiceText",
    "unpaidInvoiceCancelPlanCancelInvoiceText",
    "unpaidInvoiceChangePlanPaymentRequiredText",
    "unpaidInvoiceCancelPlanPaymentRequiredText",
    "removeMobileUsersModal"
  ];
  static values = {
    currentPlan: Number,
    hasIfobUnlimited: Boolean,
    hasIfobUnlimitedBilled: Boolean,
    hasUnpaidInvoice: Boolean,
  };

  connect() {
    this.showNewFeatureModals();
  }

  checkIfUnpaidSite() {
    if (this.hasUnpaidInvoiceWarningSubmitButtonTarget) {
      this.unpaidInvoiceWarningSubmitButtonTarget.disabled = true;
      this.unpaidInvoiceWarningSubmitButtonTarget.classList.add('d-none');
    }
    if (this.hasUnpaidInvoiceCancelPlanCancelInvoiceTextTarget) {
      this.unpaidInvoiceCancelPlanCancelInvoiceTextTarget.classList.add('d-none');
    }
    if (this.hasUnpaidInvoiceCancelPlanPaymentRequiredTextTarget) {
      this.unpaidInvoiceCancelPlanPaymentRequiredTextTarget.classList.add('d-none');
    }
    if (this.hasUnpaidInvoiceChangePlanCancelInvoiceTextTarget) {
      this.unpaidInvoiceChangePlanCancelInvoiceTextTarget.classList.add('d-none');
    }
    if (this.hasUnpaidInvoiceChangePlanPaymentRequiredTextTarget) {
      this.unpaidInvoiceChangePlanPaymentRequiredTextTarget.classList.add('d-none');
    }
    if (!this.hasPlanSelectionTarget) {
      return;
    }
    if (this.currentPlanValue == 1 && this.hasIfobUnlimitedValue && this.hasIfobUnlimitedBilledValue) {
        this.updateSiteEditFormTarget.submit();
      return;
    }
    var selectedPlan = this.getSelectedPlan();
    if (this.hasUnpaidInvoiceValue) {
      if (selectedPlan == 99 && this.currentPlanValue == 1 && this.hasIfobUnlimitedValue && !this.hasIfobUnlimitedBilledValue) {
        // Pay Once plan, not changed
        this.updateSiteEditFormTarget.submit();
        return;
      }
      if ((selectedPlan != this.currentPlanValue) || (selectedPlan == 1 && this.hasIfobUnlimitedValue && !this.hasIfobUnlimitedBilledValue)) {
        if (selectedPlan == 0) {
          if (this.hasUnpaidInvoiceCancelPlanCancelInvoiceTextTarget) {
            this.unpaidInvoiceCancelPlanCancelInvoiceTextTarget.classList.remove('d-none');
            if (this.hasUnpaidInvoiceWarningSubmitButtonTarget) {
              this.unpaidInvoiceWarningSubmitButtonTarget.disabled = false;
              this.unpaidInvoiceWarningSubmitButtonTarget.classList.remove('d-none');
            }
          } else if (this.hasUnpaidInvoiceCancelPlanPaymentRequiredTextTarget) {
            this.unpaidInvoiceCancelPlanPaymentRequiredTextTarget.classList.remove('d-none');
          }
        } else if (this.hasUnpaidInvoiceChangePlanCancelInvoiceTextTarget) {
          this.unpaidInvoiceChangePlanCancelInvoiceTextTarget.classList.remove('d-none');
          if (this.hasUnpaidInvoiceWarningSubmitButtonTarget) {
            this.unpaidInvoiceWarningSubmitButtonTarget.disabled = false;
            this.unpaidInvoiceWarningSubmitButtonTarget.classList.remove('d-none');
          }
        } else if (this.hasUnpaidInvoiceChangePlanPaymentRequiredTextTarget) {
          this.unpaidInvoiceChangePlanPaymentRequiredTextTarget.classList.remove('d-none');
        }
        if (!this.unpaidInvoiceWarningModal) {
          this.unpaidInvoiceWarningModal = new bootstrap.Modal(this.unpaidInvoiceWarningModalTarget, { keyboard: false });
        }
        if (this.unpaidInvoiceWarningModal) {
          this.unpaidInvoiceWarningModal.show();
        }
        return;
      }
    } else if (selectedPlan == 0) {
      if (!this.removeMobileUsersModal) {
        this.removeMobileUsersModal = new bootstrap.Modal(this.removeMobileUsersModalTarget, { keyboard: false });
      }
      if (this.removeMobileUsersModal) {
        this.removeMobileUsersModal.show();
        return;
      }
    }
    this.submitForm();
  }

  setRemoveMobileUsersAndSubmitForm(event) {
    if (this.hasRemoveMobileUsersOptionTarget) {
      this.removeMobileUsersOptionTarget.value = 1
    }
    this.submitForm(event);
  }

  submitForm(event) {
    this.updateSiteEditFormTarget.submit()
  }

  canUseHistoryChanged() {
    if (this.hasCanUseHistoryTarget && this.hasCanViewBaseInfoForHistoryTarget) {
      if (!this.canUseHistoryTarget.checked) {
        this.canViewBaseInfoForHistoryTarget.value = '';
        this.canViewBaseInfoForHistoryTarget.checked = '';
        this.canViewBaseInfoForHistoryTarget.disabled = 'true';
      } else {
        this.canViewBaseInfoForHistoryTarget.disabled = '';
      }
    }
  }

  showNewFeatureModals() {
    if (this.hasNewFeatureModalATarget) {
      var newFeatureModalA = new bootstrap.Modal(this.newFeatureModalATarget, { keyboard: true });
      if (newFeatureModalA) {
        newFeatureModalA.show()
      }
    }
    if (!this.hasNewFeatureModalATarget && this.hasNewFeatureModalBTarget) {
      var newFeatureModalB = new bootstrap.Modal(this.newFeatureModalBTarget, { keyboard: true });
      if (newFeatureModalB) {
        newFeatureModalB.show()
      }
    }
  }

  showSiriAnnouncement(event) {
    if (this.hasNewFeatureModalBTarget) {
      var newFeatureModalB = new bootstrap.Modal(this.newFeatureModalBTarget, { keyboard: true });
      if (newFeatureModalB) {
        newFeatureModalB.show()
      }
    }
  }

  searchSites(event) {
    if (this.hasSitesTableTarget) {
      var searchText = event.target.value
      if (searchText == "") {
        Array.from(this.sitesTableTarget.querySelectorAll('tbody > tr')).filter(row => {
          row.classList.remove('d-none')
        })
        return
      }
      // Array.from(document.querySelectorAll('tr[data-sites-link-param^="installer/site"]')).filter(row => {
      Array.from(this.sitesTableTarget.querySelectorAll('tbody > tr')).filter(row => {
        var rowMatched = false
        row.querySelectorAll('td').forEach(rowColumn => {
          if (!rowMatched && rowColumn.innerHTML.toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
            rowMatched = true
          }
        })
        if (rowMatched) {
          row.classList.remove('d-none')
        } else {
          row.classList.add('d-none')
        }
      })
    }
  }

  numberOnlyEntry(event) {
    if (event.charCode < 48 || event.charCode > 57) {
      event.preventDefault()
    }
  }

  openSite(event) {
    Turbo.visit(event.params['link'])
  }

  showFirmwareWarningModal(event) {
    if (!this.hasAdditionalMobileUsersAndPersonalReportingRulesCheckboxTarget) {
      return;
    }
    if (this.additionalMobileUsersAndPersonalReportingRulesCheckboxTarget.checked && this.hasOldFirmwareVersionWarningModalTarget) {
      var oldFirmwareVersionModal = new bootstrap.Modal(this.oldFirmwareVersionWarningModalTarget, { keyboard: false });
      if (oldFirmwareVersionModal) {
        oldFirmwareVersionModal.show()
      }
    }
  }

  showEmergencyVoiceWarningModal(event) {
    if (!this.hasEmergencyVoiceCallsCheckboxTarget) {
      return;
    }
    if (this.emergencyVoiceCallsCheckboxTarget.checked && this.hasEmergencyVoiceCallsModalTarget) {
      var emergencyVoiceCallsModal = new bootstrap.Modal(this.emergencyVoiceCallsModalTarget, { keyboard: false });
      if (emergencyVoiceCallsModal) {
        emergencyVoiceCallsModal.show()
      }
    } else if (!this.emergencyVoiceCallsCheckboxTarget.checked) {
      this.emergencyVoiceCallRegionsTarget.classList.add('d-none');
    }
  }

  emergencyVoiceWarningModalAgree(event) {
    if (!this.hasEmergencyVoiceCallRegionsTarget) {
      return;
    }
    this.emergencyVoiceCallRegionsTarget.classList.remove('d-none');
  }

  emergencyVoiceWarningModalCancel(event) {
    if (!this.hasEmergencyVoiceCallsCheckboxTarget) {
      return;
    }
    this.emergencyVoiceCallsCheckboxTarget.checked = false;
  }

  showRemoveAdditionalUsersAndRulesModal(event) {
    if (!this.hasAdditionalMobileUsersAndPersonalReportingRulesCheckboxTarget) {
      return;
    }
    if (!this.additionalMobileUsersAndPersonalReportingRulesCheckboxTarget.checked && this.hasRemoveAdditionalUsersAndRulesModalTarget) {
      if (!this.removeAdditionalUsersAndRulesModal) {
        this.removeAdditionalUsersAndRulesModal = new bootstrap.Modal(this.removeAdditionalUsersAndRulesModalTarget, { keyboard: false });
      }
      if (this.removeAdditionalUsersAndRulesModal) {
        this.removeAdditionalUsersAndRulesModal.show()
      }
    }
  }

  resetAdditionalUsersAndRules(event) {
    if (!this.hasAdditionalMobileUsersAndPersonalReportingRulesCheckboxTarget) {
      return;
    }
    this.additionalMobileUsersAndPersonalReportingRulesCheckboxTarget.checked = true;
    if (this.removeAdditionalUsersAndRulesModal) {
      this.removeAdditionalUsersAndRulesModal.hide()
    }
  }

  showiFobSuspensionModal(event) {
    if (!this.hasSuspendIfobCheckboxTarget) {
      return;
    }
    if (this.suspendIfobCheckboxTarget.checked) {
        this.resetIfobSuspension = true
        this.resetPersonalReportingSuspension = false
        this.showIfobOrPersonalReportingSuspensionModal()
    }
  }

  showPersonalReportingSuspensionModal(event) {
    if (!this.hasSuspendPersonalReportingCheckboxTarget) {
      return;
    }
    if (this.suspendPersonalReportingCheckboxTarget.checked) {
        this.resetIfobSuspension = false
        this.resetPersonalReportingSuspension = true
        this.showIfobOrPersonalReportingSuspensionModal()
    }
  }

  showIfobOrPersonalReportingSuspensionModal() {
    if (this.hasSuspendIfobOrPersonalReportingModalTarget) {
      if (!this.suspendIfobOrPersonalReportingModal) {
        this.suspendIfobOrPersonalReportingModal = new bootstrap.Modal(this.suspendIfobOrPersonalReportingModalTarget, { keyboard: false });
      }
      if (this.suspendIfobOrPersonalReportingModal) {
        this.suspendIfobOrPersonalReportingModal.show()
      }
    }
  }

  resetSuspension(event) {
    if (!this.hasSuspendIfobCheckboxTarget || !this.hasSuspendPersonalReportingCheckboxTarget) {
      return;
    }
    if (this.resetIfobSuspension) {
      this.suspendIfobCheckboxTarget.checked = false
    }
    if (this.resetPersonalReportingSuspension) {
      this.suspendPersonalReportingCheckboxTarget.checked = false
    }
    this.additionalMobileUsersAndPersonalReportingRulesCheckboxTarget.checked = true;
    if (this.suspendIfobOrPersonalReportingModal) {
      this.resetIfobSuspension = false
      this.resetPersonalReportingSuspension = false
      this.suspendIfobOrPersonalReportingModal.hide()
    }
  }

  showRemoveAdvancedPersonalReportingModal(event) {
    if (!this.hasAdvancedPersonalReportingCheckboxTarget) {
      return;
    }
    if (!this.advancedPersonalReportingCheckboxTarget.checked && this.hasAdvancedPersonalReportingModalTarget) {
      if (!this.advancedPersonalReportingModal) {
        this.advancedPersonalReportingModal = new bootstrap.Modal(this.advancedPersonalReportingModalTarget, { keyboard: false });
      }
      if (this.advancedPersonalReportingModal) {
        this.advancedPersonalReportingModal.show()
      }
    }
  }

  resetAdvancedPersonalReporting(event) {
    if (!this.hasAdvancedPersonalReportingCheckboxTarget) {
      return;
    }
    this.advancedPersonalReportingCheckboxTarget.checked = true;
    if (this.advancedPersonalReportingModal) {
      this.advancedPersonalReportingModal.hide()
    }
  }

  toggleRepeatBilling(event) {
    if (!this.hasYearlyBillingCheckboxTarget) {
      return;
    }
    if (this.yearlyBillingCheckboxTarget.checked && this.hasRepeatBillingTarget) {
      this.repeatBillingTarget.classList.remove('d-none')
      this.oneHourPollFailMonthlyTarget.classList.add('d-none')
      this.oneHourPollFailYearlyTarget.classList.remove('d-none')
      this.advancedPersonalReportingMonthlyTarget.classList.add('d-none')
      this.advancedPersonalReportingYearlyTarget.classList.remove('d-none')
      this.additionalMobileUsersAndPersonalReportingRulesMonthlyTarget.classList.add('d-none')
      this.additionalMobileUsersAndPersonalReportingRulesYearlyTarget.classList.remove('d-none')
      if (this.hasDualMyAlarmSimsMonthlyTarget) {
        this.dualMyAlarmSimsMonthlyTarget.classList.add('d-none')
        this.dualMyAlarmSimsYearlyTarget.classList.remove('d-none')
      }
      if (this.hasEmergencyVoiceCallsMonthlyTarget) {
        this.emergencyVoiceCallsMonthlyTarget.classList.add('d-none')
        this.emergencyVoiceCallsYearlyTarget.classList.remove('d-none')
      }

    } else {
      this.repeatBillingTarget.classList.add('d-none')
      this.oneHourPollFailMonthlyTarget.classList.remove('d-none')
      this.oneHourPollFailYearlyTarget.classList.add('d-none')
      this.advancedPersonalReportingMonthlyTarget.classList.remove('d-none')
      this.advancedPersonalReportingYearlyTarget.classList.add('d-none')
      this.additionalMobileUsersAndPersonalReportingRulesMonthlyTarget.classList.remove('d-none')
      this.additionalMobileUsersAndPersonalReportingRulesYearlyTarget.classList.add('d-none')
      if (this.hasDualMyAlarmSimsMonthlyTarget) {
        this.dualMyAlarmSimsMonthlyTarget.classList.remove('d-none')
        this.dualMyAlarmSimsYearlyTarget.classList.add('d-none')
      }
      if (this.hasEmergencyVoiceCallsMonthlyTarget) {
        this.emergencyVoiceCallsMonthlyTarget.classList.remove('d-none')
        this.emergencyVoiceCallsYearlyTarget.classList.add('d-none')
      }
    }
  }

  toggleYearlyBilling(event) {
    if (!this.hasYearlyBillingTarget) {
      return
    }
    var selectedPlan = this.getSelectedOption(event)
    var totalPlans = event.params['totalPlans']
    if ([1, 2, 3, 4, 5].includes(selectedPlan)) {
      this.yearlyBillingTarget.classList.remove('d-none')
      if ([2, 3, 4, 5].includes(selectedPlan) && this.hasPlanOptionsTarget) {
        this.planOptionsTarget.classList.remove('d-none')
        this.updatePlanOptions(selectedPlan, [2, 3, 4, 5])
        this.updateReportingPlanOptions(selectedPlan, [2, 3, 5])
        this.updateRadioPlanOptions(selectedPlan, [4, 5])
      } else {
        this.hideAllPlanOptions(selectedPlan)
      }
    } else {
      this.yearlyBillingTarget.classList.add('d-none')
      this.hideAllPlanOptions(selectedPlan)
    }
  }

  hideAllPlanOptions(selectedPlan) {
      if (this.hasPlanOptionsTarget) {
        this.planOptionsTarget.classList.add('d-none')
      }
      this.updatePlanOptions(selectedPlan, []);
      this.updateReportingPlanOptions(selectedPlan, []);
      this.updateRadioPlanOptions(selectedPlan, [])
  }

  updatePlanOptions(selectedPlan, planOptionSites) {
    if (planOptionSites.includes(selectedPlan)) {
      if (this.hasOneHourPollFailTarget) {
        this.oneHourPollFailTarget.classList.remove('d-none')
      }
    } else {
      if (this.hasOneHourPollFailTarget) {
        this.oneHourPollFailTarget.classList.add('d-none')
      }
    }
  }

  updateReportingPlanOptions(selectedPlan, planOptionSites) {
    if (planOptionSites.includes(selectedPlan)) {
      if (this.hasAdvancedPersonalReportingTarget) {
        this.advancedPersonalReportingTarget.classList.remove('d-none')
      }
      if (this.hasAdditionalMobileUsersAndPersonalReportingRulesTarget) {
        this.additionalMobileUsersAndPersonalReportingRulesTarget.classList.remove('d-none')
      }
      if (this.hasEmergencyVoiceCallsTarget) {
        this.emergencyVoiceCallsTarget.classList.remove('d-none')
      }
    } else {
      if (this.hasAdvancedPersonalReportingTarget) {
        this.advancedPersonalReportingTarget.classList.add('d-none')
      }
      if (this.hasAdditionalMobileUsersAndPersonalReportingRulesTarget) {
        this.additionalMobileUsersAndPersonalReportingRulesTarget.classList.add('d-none')
      }
      if (this.hasEmergencyVoiceCallsTarget) {
        this.emergencyVoiceCallsTarget.classList.add('d-none')
      }
    }
  }


  updateRadioPlanOptions(selectedPlan, planOptionSites) {
    if (planOptionSites.includes(selectedPlan)) {
      if (this.hasRadioLinkTarget) {
        this.radioLinkTarget.classList.remove('d-none')
      }
      if (this.hasRadioSerialInputTarget) {
        this.radioSerialInputTarget.required = true
        if (this.radioSerialInputTarget.value != '') {
          if (this.hasDualSimsTarget) {
            this.dualSimsTarget.classList.remove('d-none')
          }
        }
      } else if (this.hasHiddenRadioSerialInputTarget) {
        if (this.hasDualSimsTarget) {
            this.dualSimsTarget.classList.remove('d-none')
        }
      }
    } else {
      if (this.hasRadioLinkTarget) {
        this.radioLinkTarget.classList.add('d-none')
      }
      if (this.hasDualSimsTarget) {
        this.dualSimsTarget.classList.add('d-none')
      }
      if (this.hasRadioSerialInputTarget) {
        this.radioSerialInputTarget.removeAttribute('required')
      }
    }
  }

  changeSiteSortOrdering(event) {
    if (this.hasChangeSiteSortOrderFormTarget) {
      this.changeSiteSortOrderFormTarget.submit();
    }
  }

  getSelectedOption(event) {
    return Number([...event.target.selectedOptions].map(option => option.value)[0])
  }

  getSelectedPlan() {
    return Number([...this.planSelectionTarget.selectedOptions].map(option => option.value)[0])
  }
}
